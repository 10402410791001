/* eslint-disable unicorn/no-array-callback-reference */
import isObject from 'lodash/isObject';
import { Node } from 'slate';

import fixExcerpt from '@sp/json-schema/scripts/fix/mutators/blog/utils/fixExcerpt';
import getPrepareContent from '@sp/json-schema/scripts/fix/mutators/blog/utils/getPrepareContent';

export const getPlainExcerpt = (excerptData) => {
  if (!isObject(excerptData)) return '';

  const excerptValue = fixExcerpt(getPrepareContent(excerptData));
  const serialize = (nodes) => nodes.map((n) => Node.string(n)).join('\n');

  return serialize(excerptValue);
};

export const convertStringToDom = (htmlString) => document.createRange().createContextualFragment(htmlString);

const filterBlockquotes = ({ type }) => type === 'blockquote1' || type === 'blockquote2';

export const isBlockquotePresent = (excerptData) => excerptData.some(filterBlockquotes);

export const blockquoteHandle = (excerptData, parentPostDomNode) => {
  if (isBlockquotePresent) {
    const blockquotesData = excerptData.filter(filterBlockquotes);
    const blockquoteNodes = parentPostDomNode.querySelectorAll('blockquote');
    let textDecorationsRef = [];

    for (let i = 0; i < blockquoteNodes.length; i += 1) {
      const blockquoteNode = blockquoteNodes[i];
      const hasCustomDecoration = false;
      const { children } = blockquoteNode;
      const blockquoteDom = children[0];
      const computedStyle = getComputedStyle(blockquoteDom, null)['text-decoration-line'];
      const computedDecoration = {
        underline: computedStyle.includes('underline'),
        strikethrough: computedStyle.includes('line-through'),
      };

      const { children: childElements } = blockquotesData[i];
      let isHasCustomDecoration = hasCustomDecoration;

      textDecorationsRef = [];
      isHasCustomDecoration = false;

      for (let j = 0; j < childElements.length; j += 1) {
        const { underline, strikethrough } = childElements[j];
        const hasUnderline = underline ?? computedDecoration.underline;
        const hasStrikethrough = strikethrough ?? computedDecoration.strikethrough;

        isHasCustomDecoration = underline !== undefined || strikethrough !== undefined || isHasCustomDecoration;

        textDecorationsRef.push({
          underline: hasUnderline,
          strikethrough: hasStrikethrough,
        });
      }

      if (isHasCustomDecoration) {
        blockquoteDom.classList.add('disable_decoration');

        for (let j = 0; j < textDecorationsRef.length; j += 1) {
          const textDecoration = textDecorationsRef[j];
          const node = blockquoteDom.children[0].children[j];

          const classesDecorations = Object.keys(textDecoration).reduce((acc, className) => {
            if (textDecoration[className]) {
              acc.push(`_${className}`);
            }

            return acc;
          }, []);

          node.classList.add(...classesDecorations);
        }
      }
    }
  }
};

import datepicker from 'js-datepicker';
import set from 'lodash/set';

import { checkIsContrasted } from '../../helpers/color';
import dom from '../../wrapper/DomWrapper';

import {
  CUSTOM_DAYS,
  DATE_OPTIONS, DATEPICKER_COLORS,
  DATEPICKER_WRAPPER_CLASSES,
  DAY_FIRST, RATIO,
} from './constants';
import { removeExtraSpaces, replaceCalendarPosition } from './helpers';

class Datepickers {
  constructor(formValues, validateHandler) {
    this.formValues = formValues;
    this.datepickers = {};
    this.datepickersIDs = [];
    this.validateHandler = validateHandler;
  }

  setDatepickerById = ({
    fieldId,
    formId,
    currentFieldId,
    dataset,
    fieldRowId,
  }) => {
    const { dateFormat } = dataset;
    let datepickerSelector = document.querySelector(`[id="${fieldId}"]`);

    // if the datepicker is already initialized, we need to change id
    // this can be when we have cloned forms on the page
    if (this.datepickersIDs.includes(fieldId)) {
      const cloneFieldId = `${fieldId}-clone-${this.datepickersIDs.length}`;
      const formSelector = document.querySelector(`[id="${formId}"]`);

      datepickerSelector = formSelector.querySelector(`[id="${fieldId}"]`);
      datepickerSelector.id = cloneFieldId;
      this.datepickersIDs.push(cloneFieldId);
    } else {
      this.datepickersIDs.push(fieldId);
    }

    this.setDatepicker({
      datepickerSelector,
      formId,
      currentFieldId,
      dateFormat,
      fieldRowId,
      fieldId: datepickerSelector.id,
    });
    this.setDatepickerContrastRatio(datepickerSelector);
  };

  setDatepicker = ({
    datepickerSelector,
    formId,
    currentFieldId,
    dateFormat,
    fieldRowId,
    fieldId,
  }) => {
    const picker = this.setDatepickerOptions({
      datepickerSelector,
      formId,
      currentFieldId,
      dateFormat,
      fieldId,
    });

    this.setToggleDatepickerOnIconClick({
      datepickerSelector,
      picker,
      fieldId,
    });

    this.initDatepickerInstances({
      datepickerSelector,
      formId,
      currentFieldId,
      dateFormat,
      picker,
      fieldRowId,
      fieldId,
    });
  };

  setDatepickerOptions = ({
    datepickerSelector,
    formId,
    currentFieldId,
    dateFormat,
    fieldId,
  }) => {
    const locale = dateFormat === DAY_FIRST ? 'en-GB' : 'en-US';

    return datepicker(datepickerSelector, {
      customDays: CUSTOM_DAYS,
      disableYearOverlay: true,
      showAllDates: true,
      maxDate: new Date(9999, 12, 31),

      formatter: (input, date) => {
        // eslint-disable-next-line no-param-reassign
        input.value = date.toLocaleDateString(locale, DATE_OPTIONS);
      },

      onShow: replaceCalendarPosition,

      onSelect: (instance, date) => {
        const value = date.toLocaleDateString(locale, DATE_OPTIONS);

        set(
          this.formValues,
          `${formId}.${currentFieldId}`,
          removeExtraSpaces(value)
        );

        // hack to NOT DELETE the date when pressed again on the selected date
        // eslint-disable-next-line no-param-reassign
        instance.dateSelected = new Date();

        this.validateHandler(
          value,
          currentFieldId,
          fieldId,
          formId
        );
      },
    });
  };

  setToggleDatepickerOnIconClick = ({
    datepickerSelector,
    picker,
    fieldId,
  }) => {
    const inputWrapper = datepickerSelector.closest(DATEPICKER_WRAPPER_CLASSES.WRAPPER);
    const icon = inputWrapper.querySelector(DATEPICKER_WRAPPER_CLASSES.ICON_HOLDER);

    icon.addEventListener('click', (e) => {
      e.stopPropagation();

      const isHidden = picker.calendarContainer.classList.contains(DATEPICKER_WRAPPER_CLASSES.QS_HIDDEN);

      picker[isHidden ? 'show' : 'hide']();
      this.hideOtherDatepickers(fieldId);
    });
  };

  hideOtherDatepickers = (fieldId) => {
    Object.values(this.datepickers).forEach((form) => {
      form.instances.forEach((picker) => {
        if (picker.el.id !== fieldId) {
          picker.hide();
        }
      });
    });
  };

  initDatepickerInstances = ({
    datepickerSelector,
    formId,
    currentFieldId,
    dateFormat,
    picker,
    fieldRowId,
    fieldId,
  }) => {
    if (!this.datepickers[formId]) {
      this.datepickers[formId] = {
        instances: [],
        params: {},
      };
    }

    this.datepickers[formId].instances.push(picker);

    this.datepickers[formId].params[currentFieldId] = {
      datepickerSelector,
      formId,
      currentFieldId,
      dateFormat,
      fieldId,
      fieldRowId,
    };
  };

  reInitDatepickers = (id) => {
    if (this.datepickers[id]) {
      this.datepickers[id].instances.forEach((picker) => picker.remove());
      this.datepickers[id].instances = [];

      Object.values(this.datepickers[id].params).forEach(({
        datepickerSelector,
        formId,
        currentFieldId,
        dateFormat,
        fieldId,
        fieldRowId,
      }) => this.setDatepicker({
        datepickerSelector,
        formId,
        currentFieldId,
        dateFormat,
        fieldId,
        fieldRowId,
      }));
    }
  };

  // eslint-disable-next-line class-methods-use-this
  setDatepickerContrastRatio = (datepickerSelector) => {
    const inputWrapper = datepickerSelector.closest(DATEPICKER_WRAPPER_CLASSES.WRAPPER);

    if (!inputWrapper) return;

    const datepickerContainer = dom.getElement(DATEPICKER_WRAPPER_CLASSES.CONTAINER, inputWrapper);

    if (!datepickerContainer) return;

    const isElementsContrastedToBackground = checkIsContrasted(
      DATEPICKER_COLORS.TEXT,
      DATEPICKER_COLORS.BACKGROUND,
      RATIO.MAIN
    ) && checkIsContrasted(DATEPICKER_COLORS.BRAND, DATEPICKER_COLORS.BACKGROUND, RATIO.MAIN);
    const {
      TEXT, BACKGROUND, BRAND, COMPLEMENTARY, HOVER,
    } = DATEPICKER_COLORS;

    const isInactiveDaysContrastedToBackground = checkIsContrasted(COMPLEMENTARY, BACKGROUND, RATIO.SECONDARY);

    const isElementsContrastedToHoveredDate = checkIsContrasted(BRAND,
      HOVER,
      RATIO.SECONDARY
    ) && checkIsContrasted(TEXT, HOVER, RATIO.SECONDARY);

    if (isElementsContrastedToBackground
      && isInactiveDaysContrastedToBackground
      && isElementsContrastedToHoveredDate) return;

    dom.addClass(datepickerContainer, DATEPICKER_WRAPPER_CLASSES.NOT_CONTRASTED);
  };
}

export default Datepickers;

export const HERO_SELECTOR = '.post-hero';
export const COMMENTS_SELECTOR = '.post-page__comments';
export const NAVIGATION_SELECTOR = '.post-page__navigation';
export const POSTSOCIAL_SELECTOR = '.post-page__social';
export const SOCIALBUTTONS_SELECTOR = '.post-page__social-wrapper';
export const POSTIMAGE_SELECTOR = '.post-image';
export const ORDER_BY = {
  oldest: 'time',
  newest: 'reverse_time',
};

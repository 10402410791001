const cutNumber = require('./cutNumber');

const QUALITY_RATIO = 1.2;
const COVER_TYPES = new Set(['cover', 'grid']);

/**
 * Helper for generate url for image-resize service
 * @param {string} src
 * @param {string} layout
 * @param {Object} dynamic
 * @param {string} imageResizeApiService
 * @returns {string}
 */
const getImageResizeUrl = (
  src,
  // eslint-disable-next-line default-param-last
  layout = 'image_desktop',
  // eslint-disable-next-line default-param-last
  dynamic = {},
  imageResizeApiService
) => {
  const {
    type,
    originalSize,
    transform = {},
  } = dynamic;
  const isContain = type === 'contain';
  const isCover = COVER_TYPES.has(type);
  const layoutTransform = transform[layout];

  if (!layoutTransform) return src;

  const {
    scale,
    wrap_width: width,
    wrap_height: height,
    offset: {
      x,
      y,
    } = {},
  } = layoutTransform;
  const indData = {
    x: cutNumber(x) || 0,
    y: cutNumber(y) || 0,
    scale: cutNumber(scale) || 1,
    width: cutNumber(width),
    height: cutNumber(height),
  };

  const containSrc = `${imageResizeApiService}?url=${src}`;

  if (isCover) return `${containSrc}&height=${indData.height}&width=${indData.width}&scale=${indData.scale}&x=${indData.x}&y=${indData.y}`;

  if (indData.height) {
    let newHeight = indData.height;

    if (isContain && originalSize?.height) { // photo component with contain type
      newHeight = indData.height * QUALITY_RATIO;

      if (newHeight <= originalSize.height) {
        return `${containSrc}&height=${cutNumber(newHeight)}`;
      }
    }

    return `${containSrc}&height=${newHeight}`;
  }

  if (indData.width) {
    return `${containSrc}&width=${indData.width}`;
  }

  return src;
};

module.exports = getImageResizeUrl;
